<template>
<CRow>
  <CCol col="12" xl="12">
    <CCard>
      <form id="UpdateSupplier" @submit.prevent="UpdateSupplier" >
        <CCardHeader>
            <CCol col="6" class="styleHeader"> {{$t('message.updateSupplier')}} </CCol>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              <CInput :label=" $t('message.supplierName') "
                id="name"
                v-model="suppliers.name"
                class="required"
                 :placeholder="$t('message.supplierName')"
                :invalidFeedback="errors.name"
                required
              />
            </CCol>
          </CRow>
           <CRow>
            <CCol sm="12">
              <CInput :label="$t('message.supplierEmail')"
                id="email"
                v-model="suppliers.email"
                class="required"
                :placeholder="$t('message.supplierEmail')"
                :invalidFeedback="errors.email"
                required
              />
            </CCol>
          </CRow>
           <CRow>
            <CCol sm="12">
              <CInput :label=" $t('message.supplierPhone')"
                id="phone"
                v-model="suppliers.phone"
                class="required"
                :placeholder=" $t('message.supplierPhone')"
                :invalidFeedback="errors.phone"
                required
              />
            </CCol>
          </CRow>

            </CCardBody>
           <CCardFooter>
            <CButton type="submit" size="sm" color="primary" >
            <CIcon name="cil-check-circle"/> {{ $t('message.save')}}</CButton>
              &emsp;
            <CButton type="button" size="sm" color="btn btn-primary" @click="goBack">
            <CIcon name="cil-ban"/> {{ $t('message.back') }} </CButton>
          </CCardFooter>
          </form>
        </CCard>
    </CCol>
</CRow>
</template>

<script>
export default {
  name: 'EditSupplier',
  data () {
    return {
      suppliers: [],
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}suppliers/${this.$route.params.id}`)
      .then((response) => {
        this.suppliers = response.data.data
      })
  },
  methods: {
    async UpdateSupplier () {
      const formData = new FormData()
      formData.append('name', this.suppliers.name)
      formData.append('email', this.suppliers.email)
      formData.append('phone', this.suppliers.phone)
      formData.append('_method', 'PUT')

      this.errors = await this.postRequest(formData, `${this.$hostUrl}suppliers/${this.$route.params.id}`, '/suppliers')
    },
    goBack () {
      this.areasOpened ? this.$router.go(-1) : this.$router.push({ path: '/suppliers' })
    }
  }
}
</script>
